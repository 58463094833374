<template>
  <div class="demo-result-main">
    <div class="heading" style="display: inline-flex;">
      <p class="light-text pt-2">
        Search result:
        <span class="secondary-text weight-400 font-lg-14">{{
          userdata && userdata.search_data && userdata.search_data.length > 0
            ? userdata.search_data
            : "No data found"
        }}</span>
        <span
          ><svg
            @click="flag()"
            style="height: 17px; width: 18px"
            id="Layer_1___-766000037"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 31.96 36.37"
          >
            <title></title>
            <rect width="31.96" height="36.37" fill="none"></rect>
            <path
              id="_-e-Flag_Icon___-766000037"
              data-name=" -e-Flag Icon"
              d="M27.68,4.05a1.34,1.34,0,0,0-1-.25L24,4.45A13.81,13.81,0,0,1,16,4H16a16.25,16.25,0,0,0-8.73-.7l-1,.2V3.2a1.27,1.27,0,1,0-2.53,0h0v30a1.25,1.25,0,1,0,2.49,0h0V20.05l1.45-.3a13.25,13.25,0,0,1,7.38.6h.1a16.52,16.52,0,0,0,5.59,1,15.28,15.28,0,0,0,4-.5l2.59-.65a1.28,1.28,0,0,0,1-1.2v-14A1.55,1.55,0,0,0,27.68,4.05Z"
              fill="#B5BBC0"
            ></path>
          </svg>
        </span>
      </p>
      <button class="input-group1-text" @click="downloadSearchResult()">
        Developer ? Download Json Result
        <svg
          width="14"
          height="18"
          viewBox="0 0 14 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.37082 15.7988H12.4833C12.7179 15.7989 12.9438 15.888 13.1152 16.0482C13.2866 16.2084 13.3909 16.4276 13.4069 16.6617C13.4229 16.8958 13.3494 17.1272 13.2014 17.3092C13.0533 17.4912 12.8417 17.6102 12.6092 17.6422L12.4833 17.6509H1.37082C1.1362 17.6508 0.910348 17.5617 0.738906 17.4015C0.567464 17.2413 0.463215 17.022 0.447223 16.788C0.431231 16.5539 0.504689 16.3224 0.652753 16.1404C0.800817 15.9584 1.01245 15.8394 1.24488 15.8074L1.37082 15.7988H12.4833H1.37082ZM6.80111 0.990821L6.92705 0.982178C7.15083 0.982187 7.36704 1.06323 7.53569 1.21031C7.70433 1.3574 7.81402 1.56058 7.84445 1.78228L7.85309 1.90822V11.4007L10.6374 8.61767C10.7944 8.4607 11.0026 8.36538 11.224 8.34906C11.4454 8.33274 11.6654 8.39652 11.8437 8.52877L11.9474 8.61767C12.1044 8.7747 12.1997 8.98289 12.216 9.20431C12.2324 9.42574 12.1686 9.64566 12.0363 9.82399L11.9474 9.92771L7.58146 14.2924C7.42466 14.4493 7.21679 14.5446 6.99564 14.5612C6.77449 14.5777 6.55475 14.5143 6.37637 14.3826L6.27265 14.2924L1.90669 9.92771C1.741 9.76247 1.64401 9.54064 1.63522 9.3068C1.62642 9.07297 1.70648 8.84447 1.85929 8.66726C2.01211 8.49005 2.22634 8.37725 2.45893 8.35156C2.69152 8.32587 2.9252 8.38919 3.11301 8.52877L3.21673 8.61767L6.00102 11.4032V1.90822C6.00103 1.68444 6.08207 1.46823 6.22915 1.29959C6.37624 1.13094 6.57942 1.02125 6.80111 0.990821L6.92705 0.982178L6.80111 0.990821Z"
            fill="white"
          />
        </svg>
      </button>
    </div>
    <!-- perosnal information -->
    <div v-if="userdata && userdata.fullApi" class="personal-info-main">
      <div
        class="scetion-1 section-3"
        v-if="
          !userdata.person_job_title &&
            !userdata.person_extracted_role &&
            !userdata.person_extracted_seniority &&
            !userdata.person_location &&
            !userdata.person_extracted_gender &&
            !userdata.person_date_of_birth &&
            !userdata.person_age
        "
      >
        <div class="comp-detail" style="width:100%;">
          <strong class="d-block font-lg-12 weight-600 text-center"
            >Could not find data for Person
          </strong>
        </div>
      </div>
      <div
        v-else-if="
          !userdata.person_job_title ||
            !userdata.person_extracted_role ||
            !userdata.person_extracted_seniority ||
            !userdata.person_location ||
            !userdata.person_extracted_gender ||
            !userdata.person_date_of_birth ||
            !userdata.person_age
        "
      >
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="first-section">
              <div
                v-if="
                  userdata &&
                    userdata.person_job_title &&
                    userdata.person_job_title.length > 0
                "
                class="personal-deatail pt-0"
              >
                <strong class="d-block font-lg-14">{{
                  userdata.person_job_title | titleize
                }}</strong>
                <span class="font-lg-12 light-text">Employment</span>
              </div>
              <div
                v-if="
                  userdata &&
                    userdata.person_extracted_role &&
                    userdata.person_extracted_role.length > 0
                "
                class="personal-deatail"
              >
                <strong class="d-block font-lg-14">{{
                  userdata.person_extracted_role | titleize
                }}</strong>
                <span class="font-lg-12 light-text">Role</span>
              </div>
              <div
                v-if="
                  userdata &&
                    userdata.person_extracted_seniority &&
                    userdata.person_extracted_seniority.length > 0
                "
                class="personal-deatail"
              >
                <strong class="d-block font-lg-14">{{
                  userdata.person_extracted_seniority | titleize
                }}</strong>
                <span class="font-lg-12 light-text">Seniority</span>
              </div>
              <div
                v-if="
                  userdata &&
                    userdata.person_location &&
                    userdata.person_location.length > 0
                "
                class="personal-deatail"
              >
                <strong class="d-block font-lg-14">{{
                  userdata.person_location | titleize
                }}</strong>
                <span class="font-lg-12 light-text">Location</span>
              </div>
              <div
                v-if="
                  userdata &&
                    userdata.person_extracted_gender &&
                    userdata.person_extracted_gender.length > 0
                "
                class="personal-deatail"
              >
                <strong class="d-block font-lg-14">{{
                  userdata.person_extracted_gender | titleize
                }}</strong>
                <span class="font-lg-12 light-text">Gender</span>
              </div>
              <div
                v-if="
                  userdata &&
                    userdata.person_date_of_birth &&
                    userdata.person_date_of_birth.length > 0
                "
                class="personal-deatail"
              >
                <strong class="d-block font-lg-14">{{
                  userdata.person_date_of_birth
                }}</strong>
                <span class="font-lg-12 light-text">Date of birth</span>
              </div>
              <div
                v-if="
                  userdata &&
                    userdata.person_age &&
                    userdata.person_age.length > 0
                "
                class="personal-deatail"
              >
                <strong class="d-block font-lg-14">{{
                  userdata.person_age
                }}</strong>
                <span class="font-lg-12 light-text">Age</span>
              </div>
            </div>
          </div>

          <div class="col-lg-8 col-md-6">
            <div class="profession-info">
              <h3>
                {{
                  userdata && userdata.person_name ? userdata.person_name : ""
                }}
              </h3>
              <a
                style="font-size: 14px"
                v-bind:href="userdata.company_detail_doamin_name"
                target="_blank"
                >{{
                  userdata && userdata.company_detail_doamin_name
                    ? userdata.company_detail_doamin_name
                    : ""
                }}</a
              >
              <div class="contact-detal pt-lg-24">
                <h4 class="font-lg-16 weight-600">Contact Details</h4>
                <div class="social-links">
                  <div class="d-flex pt-lg-24">
                    <div>
                      <img src="@/assets/images/icons/e-mail.svg" alt="" />
                    </div>
                    <b-skeleton
                      style="
                       position: relative;
                       top: 7px;
                       }"
                      v-if="emailLoad"
                      animation="fade"
                      width="12%"
                      height="35px"
                    ></b-skeleton>
                    <div>
                      <p
                        v-if="
                          userdata &&
                            emailRes.email &&
                            emailRes.email.length > 0
                        "
                        class="text-secondary weight-600 font-lg-12"
                      >
                        Email<br /><span
                          class="text-primary font-lg-14 weight-400"
                          >{{ emailRes.email }}<br />
                        </span>
                        <span v-if="emailRes.status">
                          {{ emailRes.status }}</span
                        >
                      </p>
                      <span v-else>
                        <button
                          v-if="!emailLoad"
                          class="input-group-text"
                          v-on:click="
                            findUserEmail(userdata);
                            emailLoad = true;
                          "
                        >
                          Reveal email
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div>
                      <img
                        src="@/assets/images/icons/Phone Square.svg"
                        alt=""
                      />
                    </div>
                    <div>
                      <p class="text-secondary weight-600 font-lg-12">
                        Professional Direct Dial<br /><span
                          class="text-primary font-lg-14 weight-400"
                          >{{
                            userdata && userdata.company_phonenumber
                              ? userdata.company_phonenumber
                              : "Available on request for non European target and non European customer"
                          }}</span
                        >
                      </p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <div>
                      <img src="@/assets/images/icons/Linkedin.svg" alt="" />
                    </div>
                    <div>
                      <p class="text-secondary weight-600 font-lg-12">
                        Linkedin<br /><span
                          class="text-primary font-lg-14 weight-400"
                          v-on:click="openLinkdinUrl(userdata.linked_in_url)"
                          >{{
                            userdata &&
                            userdata.linked_in_url &&
                            userdata.linked_in_url.length > 0
                              ? userdata.linked_in_url
                              : ""
                          }}</span
                        >
                      </p>
                    </div>
                  </div>
                </div>
                <h4 class="pt-3 font-lg-16 weight-600">Last Companies</h4>
                <div></div>
                <div class="hotjar d-flex">
                  <div v-if="!userdata.person_full && userdata.linked_in_url">
                    <b-button @click="findUserSubmit(userdata.linked_in_url)"
                      >Reveal Full Profile</b-button
                    >
                    <br />
                    <span class="pt-2">Available only for paid plans</span>
                  </div>
                  <div v-if="userdata.person_full">
                    <p class="text-secondary weight-600 font-lg-12">
                      {{
                        userdata && userdata.person_experience_subtitle
                          ? userdata.person_experience_subtitle
                          : "No data found"
                      }}<br /><span class="text-primary font-lg-14 weight-400">
                        {{
                          userdata && userdata.person_experience_title
                            ? userdata.person_experience_title
                            : "No data found"
                        }}</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="work-in-comp-detail">
      <div
        class="scetion-1 section-3"
        v-if="
          !userdata.person_company &&
            !userdata.company_detail_doamin_name &&
            !userdata.company_yearfounded &&
            !userdata.company_type &&
            !userdata.company_size
        "
      >
        <!-- <div class="comp-detail" style="width:100%;">
          <strong class="d-block font-lg-12 weight-600 text-center"
            >Could not find data
          </strong>
        </div> -->
      </div>
      <div
        class="scetion-1 section-3"
        v-else-if="
          userdata.person_company ||
            userdata.company_detail_doamin_name ||
            userdata.company_yearfounded ||
            userdata.company_type ||
            userdata.company_size
        "
      >
        <div class="comp-detail">
          <div class="image-box border-custom-right position-relative">
            <div class="image-box-content">
              <strong v-if="userdata.person_company" class="font-lg-14">{{
                userdata &&
                userdata.person_company &&
                userdata.person_company.length > 0
                  ? userdata.person_company
                  : "" | titleize
              }}</strong>
              <p
                v-if="userdata.company_detail_doamin_name"
                class="font-lg-14 primary-text"
                v-on:click="openLinkdinUrl(userdata.company_detail_doamin_name)"
              >
                {{
                  userdata && userdata.company_detail_doamin_name
                    ? userdata.company_detail_doamin_name
                    : ""
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            userdata && userdata.company_yearfounded
              ? userdata.company_yearfounded
              : ""
          }}</strong>
          <span class="font-lg-12 light-text weight-400">Founded</span>
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            userdata && userdata.company_type ? userdata.company_type : ""
          }}</strong>
          <span class="font-lg-12 light-text weight-400">Type</span>
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            userdata && userdata.company_size ? userdata.company_size : ""
          }}</strong>
          <span class="font-lg-12 light-text weight-600">Size Range</span>
        </div>
      </div>

      <hr class="h-line" />
      <div class="section-2">
        <span class="font-lg-12 light-text weight-400"
          >Company Description</span
        >
        <p class="light-text font-lg-14">
          {{
            userdata && userdata.company_description
              ? userdata.company_description
              : ""
          }}
        </p>
      </div>
      <hr class="h-line" />
      <strong class="font-lg-12">Premium Company Data </strong>
      <div class="scetion-1 section-3">
        <div class="comp-detail">
          <strong
            class="d-block font-lg-12 weight-600"
            v-if="userdata.company_premium_industries !== ''"
            >{{
              userdata && userdata.company_premium_industries
                ? userdata.company_premium_industries
                : ""
            }}</strong
          >
          <strong
            class="d-block font-lg-12 weight-600"
            v-else-if="userdata.company_premium_industries == ''"
            >{{
              userdata && userdata.company_premium_categories
                ? userdata.company_premium_categories
                : ""
            }}</strong
          >
          <span class="font-lg-12 light-text weight-400">Industry </span>
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            userdata && userdata.company_premium_emplamountlink
              ? userdata.company_premium_emplamountlink
              : ""
          }}</strong>
          <span class="font-lg-12 light-text weight-400"
            >Employees in LinkedIn</span
          >
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            userdata && userdata.company_premium_headquaterAddr
              ? userdata.company_premium_headquaterAddr
              : ""
          }}</strong>
          <span class="font-lg-12 light-text weight-400">HQ Address</span>
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            userdata && userdata.company_premium_numberOfLocation
              ? userdata.company_premium_numberOfLocation
              : ""
          }}</strong>
          <span class="font-lg-12 light-text weight-400"
            >Number of location</span
          >
        </div>
      </div>

      <hr class="h-line" />
      <strong class="font-lg-12">Financial Company Data</strong>

      <div
        class="hotjar d-flex"
        v-if="userdata && !userdata.financial_comp_data"
      >
        <button
          class="input-group-text4"
          v-on:click="findUserFinancialCompDataSubmit(userdata)"
        >
          Reveal Financial Company Data
        </button>
      </div>

      <div class="scetion-1 section-3" v-if="userdata.financial_comp_data">
        <div
          class="comp-detail1"
          style="width:100%;"
          v-if="
            (!userdata.company_funding_total_amount ||
              userdata.company_funding_total_amount == '0') &&
              (!userdata.company_num_funding_rounds ||
                userdata.company_num_funding_rounds == '0') &&
              !userdata.company_last_fund_type &&
              !userdata.company_last_Date &&
              !userdata.company__full_lst_round_amount
          "
        >
          <div class="comp-detail-new">
            <strong class="d-block font-lg-12 weight-600 text-center"
              >Could not find Financial Data
            </strong>
          </div>
        </div>
        <div
          class="card-n"
          v-else-if="
            userdata.company_funding_total_amount ||
              userdata.company_num_funding_rounds ||
              userdata.company_last_fund_type ||
              userdata.company_last_Date ||
              userdata.company__full_lst_round_amount
          "
        >
          <div class="comp-detail">
            <strong class="d-block font-lg-12 weight-600">{{
              userdata && userdata.company_funding_total_amount
                ? userdata.company_funding_total_amount
                : ""
            }}</strong>
            <span class="font-lg-12 light-text weight-400"
              >Total Money Raised</span
            >
          </div>
          <div class="comp-detail">
            <strong class="d-block font-lg-12 weight-600">{{
              userdata && userdata.company_num_funding_rounds
                ? userdata.company_num_funding_rounds
                : ""
            }}</strong>
            <span class="font-lg-12 light-text weight-400"
              >Numbers of rounds</span
            >
          </div>
          <div class="comp-detail">
            <strong class="d-block font-lg-12 weight-600">{{
              userdata && userdata.company_last_fund_type
                ? userdata.company_last_fund_type
                : ""
            }}</strong>
            <span class="font-lg-12 light-text weight-400"
              >Last round Type</span
            >
          </div>
          <div class="comp-detail">
            <strong class="d-block font-lg-12 weight-600">{{
              userdata && userdata.company_last_Date
                ? userdata.company_last_Date
                : ""
            }}</strong>
            <span class="font-lg-12 light-text weight-400"
              >Last round date</span
            >
          </div>
          <div class="comp-detail">
            <strong class="d-block font-lg-12 weight-600">{{
              userdata && userdata.company__full_lst_round_amount
                ? userdata.company__full_lst_round_amount
                : ""
            }}</strong>
            <span class="font-lg-12 light-text weight-400"
              >Last round Amount</span
            >
          </div>
        </div>
      </div>

      <hr class="h-line" />
      <strong class="font-lg-12">French Company Data</strong>

      <div
        class="hotjar d-flex"
        v-if="userdata && !userdata.french_company_data"
      >
        <div class="find-group">
          <button
            class="input-group-text3"
            v-on:click="findUserFrenchCompanyDataSubmit(userdata)"
          >
            Reveal French Company Data
          </button>
        </div>
      </div>

      <div class="scetion-1 section-3" v-if="userdata.french_company_data">
        <div
          style="display: flex; justify-content: center;"
          v-if="
            (!userdata.company_french_siren ||
              userdata.company_french_siren == '0') &&
              (!userdata.company_french_codeNaf ||
                userdata.company_french_codeNaf == '0') &&
              (!userdata.company_french_effectif ||
                userdata.company_french_effectif == '0') &&
              (!userdata.company_french_d_cre_form ||
                userdata.company_french_d_cre_form == '0') &&
              (!userdata.company_french_n_tva ||
                userdata.company_french_n_tva == '0')
          "
        >
          <strong class="d-block font-lg-12 weight-600 text-center"
            >Could not find French Financial Data
          </strong>
        </div>
        <div
          v-else-if="
            userdata.company_french_siren ||
              userdata.company_french_codeNaf ||
              userdata.company_french_effectif ||
              company_french_d_cre_form ||
              userdata.company_french_n_tva
          "
        ></div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            userdata && userdata.company_french_siren
              ? userdata.company_french_siren
              : ""
          }}</strong>
          <span class="font-lg-12 light-text weight-400">Siren</span>
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            userdata && userdata.company_french_codeNaf
              ? userdata.company_french_codeNaf
              : "" | removeDotFromResponse
          }}</strong>
          <span class="font-lg-12 light-text weight-400">Code Naf</span>
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            userdata && userdata.company_french_effectif
              ? userdata.company_french_effectif
              : ""
          }}</strong>
          <span class="font-lg-12 light-text weight-400">Effectif</span>
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            userdata && userdata.company_french_d_cre_form
              ? userdata.company_french_d_cre_form
              : ""
          }}</strong>
          <span class="font-lg-12 light-text weight-400">Creation date</span>
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            userdata && userdata.company_french_n_tva
              ? userdata.company_french_n_tva
              : ""
          }}</strong>
          <span class="font-lg-12 light-text weight-400">N° TVA</span>
        </div>
      </div>
      <div
        class="scetion-1 section-3"
        style="display: flex; justify-content: center"
        v-if="
          checkcompanyfinancesData(company_finances) &&
            userdata.french_company_data
        "
      >
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600 text-center"
            >No financial data available from the SIREN directory</strong
          >
        </div>
      </div>
      <div v-else-if="userdata.french_company_data" class="scetion-1 section-3">
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            company_finances &&
            company_finances.chiffreAffaires &&
            company_finances.chiffreAffaires !== "NaN"
              ? company_finances.chiffreAffaires
              : "0" | formatCardNumber
          }}</strong>
          <span class="font-lg-12 light-text weight-400">{{
            company_finances && company_finances.annee
              ? "CA " + company_finances.annee
              : "0"
          }}</span>
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600"></strong>
          <span class="font-lg-12 light-text weight-400">Evolution CA</span>
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            company_finances &&
            company_finances.resultat &&
            company_finances.resultat !== "NaN"
              ? company_finances.resultat
              : "0" | formatCardNumber
          }}</strong>
          <span class="font-lg-12 light-text weight-400"
            >Résultat
            {{
              company_finances && company_finances.annee
                ? company_finances.annee
                : "0"
            }}
          </span>
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            company_finances &&
            company_finances.effectif &&
            company_finances.effectif !== "NaN"
              ? company_finances.effectif
              : "0" | formatCardNumber
          }}</strong>
          <span class="font-lg-12 light-text weight-400"
            >Effectif
            {{
              company_finances && company_finances.annee
                ? company_finances.annee
                : "0"
            }}
          </span>
        </div>
        <div class="comp-detail">
          <strong class="d-block font-lg-12 weight-600">{{
            company_finances &&
            company_finances.effectifGrowthRate &&
            company_finances.effectifGrowthRate !== "NaN"
              ? company_finances.effectifGrowthRate + "%"
              : "0" | formatCardNumber
          }}</strong>
          <span class="font-lg-12 light-text weight-400"
            >Evolution Effectif
          </span>
        </div>
      </div>

      <hr class="h-line" />
      <div class="section-4">
        <div
          class="google-map"
          v-if="
            userdata &&
              userdata.company_phone_geometry &&
              userdata.company_phone_geometry.location
          "
        >
          <GmapMap
            :center="userdata.company_phone_geometry.location"
            :zoom="18"
            style="width: 100%; height: 465.65px"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in userdata.markers"
              :position="m.position"
              @click="userdata.company_phone_geometry = m.position"
            />
          </GmapMap>
          <!-- <img src="@/assets/images/googlemap.png" alt="" /> -->
          <a href="#" class="secondary-text font-lg-12 weight-600 pt-3">{{
            userdata && userdata.company_phone_format_address
              ? userdata.company_phone_format_address
              : ""
          }}</a>
          <p class="font-lg-12 light-text">Location</p>
        </div>
      </div>
      <hr class="h-line" />
      <div class="section-5">
        <div class="section-heading">
          <strong class="font-lg-12">Tags</strong>
        </div>
        <div class="tags">
          <div
            v-for="(industryCate, index) in userdata.company_detail_indust_cate"
            :key="index"
          >
            <strong class="primary-text">{{
              industryCate && industryCate.value ? industryCate.value : ""
            }}</strong>
          </div>
          <strong
            class="primary-text"
            v-if="!userdata.company_detail_indust_cate"
            >""</strong
          >
        </div>
      </div>
      <hr class="h-line" />
      <div class="section-6">
        <div class="section-heading">
          <strong class="font-lg-12">Technology Categories</strong>
        </div>
        <div class="tags">
          <div
            v-for="(technologyCate,
            index) in userdata.company_technolagies_categories"
            :key="index"
          >
            <strong class="primary-text">{{
              technologyCate ? technologyCate : ""
            }}</strong>
          </div>
          <strong
            class="primary-text"
            v-if="!userdata.company_technolagies_categories"
          ></strong>
        </div>
      </div>
      <hr class="h-line" />
      <div class="section-7">
        <div class="table-responsive">
          <table class="table">
            <tr>
              <td>
                <table>
                  <thead>
                    <tr>
                      <td>
                        <strong class="font-lg-12">Contact Details</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div class="d-flex">
                          <div>
                            <img
                              src="@/assets/images/icons/Phone Square.svg"
                              alt=""
                            />
                          </div>
                          <div
                            v-if="
                              userdata && userdata.company_phone_format_number
                            "
                          >
                            <p class="text-secondary font-lg-12">
                              Phone<br /><span
                                class="text-primary font-lg-14"
                                >{{
                                  userdata &&
                                  userdata.company_phone_format_number
                                    ? userdata.company_phone_format_number
                                    : ""
                                }}</span
                              >
                            </p>
                          </div>
                          <div v-else>
                            <p class="text-secondary font-lg-12">
                              Delivered with Phone Company Data package
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div class="d-flex">
                          <div>
                            <img
                              src="@/assets/images/icons/Twitter.svg"
                              alt=""
                            />
                          </div>
                          <div v-if="userdata && userdata.company_twitter_url">
                            <p
                              class="text-secondary font-lg-12"
                              v-on:click="
                                openLinkdinUrl(userdata.company_twitter_url)
                              "
                            >
                              {{
                                userdata && userdata.company_twitter_url
                                  ? userdata.company_twitter_url
                                  : ""
                              }}<br /><span class="text-primary font-lg-14"
                                >Twitter</span
                              >
                            </p>
                          </div>
                          <div v-else>
                            <p class="text-secondary font-lg-12">
                              Delivered with Full Company Data Package
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex">
                          <div>
                            <img
                              src="@/assets/images/icons/Facebook Square.svg"
                              alt=""
                            />
                          </div>
                          <div v-if="userdata && userdata.company_face_url">
                            <p
                              class="text-secondary font-lg-12"
                              v-on:click="
                                openLinkdinUrl(userdata.company_face_url)
                              "
                            >
                              {{
                                userdata && userdata.company_face_url
                                  ? userdata.company_face_url
                                  : ""
                              }}<br /><span class="text-primary font-lg-14"
                                >Facebook</span
                              >
                            </p>
                          </div>
                          <div v-else>
                            <p class="text-secondary font-lg-12">
                              Delivered with Full Company Data Package
                            </p>
                          </div>
                          <div></div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="d-flex">
                          <div>
                            <img
                              src="@/assets/images/icons/Linkedin.svg"
                              alt=""
                            />
                          </div>
                          <div
                            v-if="
                              userdata &&
                                userdata.company_linkedIn_url &&
                                userdata.company_linkedIn_url.length > 0
                            "
                          >
                            <p
                              class="text-secondary font-lg-12"
                              v-on:click="
                                openLinkdinUrl(userdata.company_linkedIn_url)
                              "
                            >
                              {{
                                userdata &&
                                userdata.company_linkedIn_url &&
                                userdata.company_linkedIn_url.length > 0
                                  ? userdata.company_linkedIn_url
                                  : ""
                              }}
                              <br /><span class="text-primary font-lg-14"
                                >Linkedin</span
                              >
                            </p>
                          </div>
                          <div v-else>
                            <p class="text-secondary font-lg-12">
                              Delivered with Full Company Data Package
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>

                  <tr></tr>
                </table>
              </td>
              <td>
                <table>
                  <!-- <thead>
                    <tr>
                      <td style="padding-bottom: 1px">
                        <strong class="font-lg-12">Funding</strong>
                      </td>
                    </tr>
                  </thead> -->
                  <tbody>
                    <tr>
                      <td style="padding-bottom: 1px">
                        <div class="contact-detail">
                          <strong class="d-block font-lg-12 weight-600"
                            >Technologies</strong
                          >
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div class="contact-detail technology">
                          <div
                            style="
                              margin: -2px 12px 26px 1px;
                              background: rgb(247, 248, 250);
                              border-radius: 4px;
                              padding: 1px 7px;
                              font-size: 11px;
                              font-weight: 600;
                            "
                            v-for="(technology,
                            index) in userdata.company_technolagies"
                            :key="index"
                          >
                            <strong
                              class="
                                primary-text
                                d-block
                                font-lg-12
                                weight-600
                                light-bg
                              "
                              >{{ technology ? technology : "" }}</strong
                            >
                          </div>
                          <span v-if="!userdata.company_technolagies"></span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>

          <!-- </table> -->
        </div>
      </div>
      <hr class="h-line" />
      <div class="section-8">
        <div class="section-heading">
          <strong class="font-lg-12">Recent news</strong>
        </div>
        <div class="recent-news">
          <!-- <h4 class="primary-text font-lg-16">
            your saas company runs on mrr...) , write : 
          </h4> -->

          <h4 class="primary-text font-lg-16">
            Recent news about your target is a available on request, please
            contact-us to use it
          </h4>

          <!-- <p class="light-text font-lg-14">
           Recent news about your target is a available on request, please contact-us to use it
          </p> -->
          <!-- <a href="#" class="secondary-text font-lg-12">
            www.media.com/all/saas-business-model</a
          > -->
        </div>
      </div>
    </div>
    <div v-if="showProfilePopup">
      <RevealProfile
        :userData="userProfileData"
        :showProfilePopup="showProfilePopup"
        @closePopUp="closePopUp"
      />
    </div>
  </div>
</template>
<script>
import {
  SEND__MAIL,
  REVEAL_FULL_PROFILE_DATA,
} from "../../../store/constant/actions.type";
import { loadingService } from "../../common/services/loading.service";
export default {
  name: "demo-result",
  props: ["userdata", "emailRes"],
  beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
    this.personFull();
  },
  components: {
    RevealProfile: () => import("./reveal-profile-popup.vue"),
  },
  data() {
    return {
      emailLoad: false,
      fullPage: false,
      person_full: false,
      showProfilePopup: false,
      userProfileData: null,
      newData: {},
      center: {},
    };
  },
  computed: {
    company_finances: function() {
      return (
        this.userdata &&
        this.userdata.company_finances_siren &&
        this.userdata.company_finances_siren.length > 0 &&
        this.userdata.company_finances_siren[0]
      );
    },
  },
  methods: {
    downloadSearchResult() {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8, " +
          encodeURIComponent(JSON.stringify(this.userdata))
      );
      element.setAttribute("download", "searchResult.txt");
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    flag() {
      this.$confirm(
        "Do you want to flag this data as inaccurate?",
        window.location.href
      )
        .then((data) => {
          if (data == true) {
            let payload = {
              searchData: this.userdata.search_data,
            };
            if (
              this.userdata &&
              this.userdata.search_data &&
              this.userdata.searchData != ""
            ) {
              this.$store
                .dispatch(SEND__MAIL, payload)
                .then((data) => {
                  if (data && data.code == "success") {
                    loadingService.setloader(false);
                    this.flashMessage.success({
                      title: "Success",
                      message: "Message send successfully",
                    });
                  }
                })
                .catch((err) => {
                  if (err) {
                    loadingService.setloader(false);
                    this.flashMessage.error({
                      title: "Error",
                      message: err.data.description,
                    });
                  }
                });
            }
          }
        })
        .catch((error) => {
          throw error;
        });
    },
    openLinkdinUrl(linkdinurl) {
      if (this.userdata && linkdinurl !== "" && linkdinurl !== undefined) {
        window.open(linkdinurl, "_blank");
      }
    },
    findUserSubmit(linkedInUrl) {
      if (this.userProfileData) {
        this.showProfilePopup = true;
      } else {
        loadingService.setloader(true);
        this.$store
          .dispatch(REVEAL_FULL_PROFILE_DATA, { linkedInUrl: linkedInUrl })
          .then((response) => {
            loadingService.setloader(false);
            this.$eventBus.$emit("callCreditMethod");
            if (response) {
              this.userProfileData = response;
              this.showProfilePopup = true;
            }
          })
          .catch((err) => {
            if (err && err.data && err.data.code) {
              loadingService.setloader(false);
              this.flashMessage.error({
                title: "Error",
                message: err.data.message
                  ? err.data.message
                  : err.data.description
                  ? err.data.description
                  : "Error Occurs",
              });
            }
          });
      }
    },
    closePopUp() {
      this.showProfilePopup = false;
    },
    findUserFinancialCompDataSubmit(data) {
      this.$emit("updateFinancialCompData", data);
    },
    findUserFrenchCompanyDataSubmit(data) {
      this.$emit("updateFrenchCompanyData", data);
    },
    findUserEmail(data) {
      this.$emit("findUserEmail", data);
    },
    personFull() {
      this.person_full = this.userdata.person_full;
    },
    checkcompanyfinancesData(company_finances) {
      if (
        !company_finances ||
        !company_finances.chiffreAffaires ||
        company_finances.chiffreAffaires === "NaN"
      ) {
        if (!company_finances || !company_finances.annee) {
          if (
            !company_finances ||
            !company_finances.tauxCroissanceChiffreAffaires ||
            company_finances.tauxCroissanceChiffreAffaires === "NaN"
          ) {
            if (
              !company_finances ||
              !company_finances.resultat ||
              company_finances.resultat === "NaN"
            ) {
              if (!company_finances || !company_finances.annee) {
                if (
                  !company_finances ||
                  !company_finances.effectif ||
                  company_finances.effectif === "NaN"
                ) {
                  return true;
                }
              }
            }
          }
        }
      }
    },
  },
  filters: {
    // Filter definitions
    titleize(value) {
      return value.replace(/(?:^|\s|-)\S/g, (x) => x.toUpperCase());
    },
    formatCardNumber(value) {
      let f1 = value ? value.match(/.{0,1}/g) : "";
      if (f1 != "") {
        for (let index = 0; index < f1.length; index++) {
          let element = f1[index];
          var patt1 = /[0-9]/g;
          var result = element?.match(patt1);
          if (!result) {
            f1 = value ? value.match(/.{1,2}/g) : "";
            break;
          }
          break;
        }
        let f2 = [f1[0]].join("");
        f1 = f1.splice(1, f1.length)?.join("");
        f1 = f1.match(/.{1,3}/g)?.join(" ");
        return f2.concat(" ", f1);
      } else {
        return 0;
      }
    },
    removeDotFromResponse(value) {
      return value.replace(".", "");
    },
  },
  watch: {
    emailRes: function(newValue) {
      newValue;
      this.emailLoad = false;
      return;
    },
  },
  // components: { UserIntegration },
};
</script>
<style scoped>
.custom-checkbox {
  margin-right: 50px;
}
.personal-info-main {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(32, 32, 35, 0.04),
    0px 0px 2px rgba(32, 32, 35, 0.06), 0px 0px 1px rgba(32, 32, 35, 0.04);
  border-radius: 8px;
  margin: 26px 0;
  /* padding: 38px 0 58px 0; */
}
.first-section {
  background-color: #f7f8fa;
  padding: 96px 48px;
  min-height: 700px;
}
.profession-info {
  padding-top: 84px;
}
.personal-deatail {
  padding-top: 15px;
}
.social-links img,
.hotjar img,
table tr td img {
  padding: 10px;
}
.hotjar {
  position: relative;
}
.work-in-comp-detail {
  margin-top: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 0 40px;
}
.scetion-1 {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.section-3 {
  background: #f7f8fa;
  border-radius: 8px;
  padding: 50px 60px;
  margin-top: 24px;
}
.h-line {
  border: 1px solid #f7f8fa;
}
.border-custom-right:after {
  position: absolute;
  border-right: 1px solid #f7f8fa;
  content: "";
  right: -20px;
  width: 2px;
  height: 56px;
}
.google-map img {
  width: 100%;
  margin-bottom: 20px;
}
.section-heading {
  padding: 20px 0;
}
.tags {
  display: flex;
  flex-wrap: wrap;
  line-height: 49px;
}
.tags strong {
  background: #f7f8fa;
  border-radius: 4px;
  padding: 7px 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 600;
}
.contact-detail {
  float: left;
  margin: 0 22px 0 0;
}
.technology {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
}
.table td {
  border-top: none;
}
.table td {
  padding: 0.75rem 0;
}
.section-8 {
  padding-bottom: 40px;
}
.contact-detail .light-bg {
  padding: 7px 15px;
}
.section-7 tr td {
  width: 33.33%;
}
.blur_img {
  filter: blur(1px);
}
.input-group-text {
  background: #2589ff;
  color: white;
  border: none;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  border-radius: 9px;
  margin-top: 9px;
  margin-bottom: 9px;
  width: auto;
}
.input-group-text2 {
  background: #2589ff;
  color: white;
  border: none;
  align-items: center;
  left: 23px;
  cursor: pointer;
  z-index: 2;
  border-radius: 9px;
  position: absolute;
  margin-top: 9px;
  padding: 6px;
}
.input-group1-text {
  background: #2589ff;
  color: white;
  border: none;
  align-items: center;
  cursor: pointer;
  border-radius: 9px;
  margin: 9px;
}
.input-group-text4 {
  background: rgb(37 137 255);
  color: rgb(255 255 255);
  border: none;
  align-items: center;
  top: -32px;
  right: 0%;
  cursor: pointer;
  z-index: 2;
  border-radius: 9px;
  position: absolute;
  margin-top: 2px;
  padding: 6px;
}
.input-group-text3 {
  background: rgb(37 137 255);
  color: rgb(255 255 255);
  border: none;
  align-items: center;

  right: 0%;
  top: -31px;
  cursor: pointer;
  z-index: 2;
  border-radius: 9px;
  position: absolute;
  margin-top: 2px;
  padding: 6px;
}

.card-n {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

@media only screen and (max-width: 769px) {
  .section-3 {
    padding: 40px 20px;
  }
  .scetion-1 {
    display: block;
  }
  .comp-detail {
    padding-top: 10px;
  }
  .demo-main {
    padding: 0 0px;
  }
  .tags strong {
    padding: 10px;
    margin-right: 6px;
    margin-bottom: 10px;
  }
  .advance-result {
    padding: 20px 10px;
  }
  .custom-checkbox {
    margin-right: 20px;
  }
  .work-in-comp-detail {
    padding: 0 0px;
  }
  .first-section {
    padding: 36px 24px;
    min-height: 500px;
  }
}
</style>
